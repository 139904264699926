<template>
  <div class="footer">
    <div class="nav">
      <template v-for="item in routes">
        <div v-if="item.name != 'homepage'" class="item">
          <div class="list-top" @click="topClick(item)">
            {{ globalI18nKey[item.meta.title] }}
          </div>
          <template v-for="(subItem, index) in item.children">
            <div v-if="subItem.name != 'PipelineIndex' && !subItem.meta.hidden" :key="index" @click="subClick(item, subItem)" class="list-item">
              {{ globalI18nKey[subItem.meta.title] }}
            </div>
          </template>
        </div>
      </template>

      <div class="item itemErweima">
        <!-- 关注我们 -->
        <div class="list-top followUs">{{ globalI18nKey['followUs'] }}</div>
        <div class="wechart"><img src="../assets/imgs/erweima.png" /></div>
      </div>
    </div>
    <div class="line"></div>
    <div class="address">
      <div v-if="lang == 'zh'">©2021 -武汉思安医疗技术有限公司 版权所有 <a target="blank" href="https://beian.miit.gov.cn/">鄂ICP备15014511号-1</a></div>
      <div v-else>©2021 -Wuhan Sian Medical Techonlogy CO;Ltd. Copyright <a target="blank" href="https://beian.miit.gov.cn/">鄂ICP备15014511号-1</a></div>
      <div style="margin: 8px 0 0 0">
        <span v-if="lang == 'zh'"><a target="blank" href="http://www.zxzykj.com">技术支持：中讯志远(武汉)科技有限公司</a></span>
        <span v-else><a target="blank" href="http://www.zxzykj.com">Technical Support：Zhongxun Zhiyuan (Wuhan) Technology Co., Ltd.</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '@/router'
export default {
  components: {},
  data() {
    return {
      routes: routes[0].children
    }
  },
  computed: {
    active: {
      get() {
        return this.$store.state.active
      },
      set(val) {
        this.$store.commit('SET_ACTIVE', val)
      }
    },
    globalI18nKey() {
      return this.$store.state.globalI18nKey
    },
    lang() {
      return this.$store.state.lang
    },
    subactive: {
      get() {
        return this.$store.state.subactive
      },
      set(val) {
        this.$store.commit('SET_SUBACTIVE', val)
      }
    }
  },

  methods: {
    topClick(item) {
      const path = item.redirect ? item.redirect : '/' + item.path
      const subPath = item.children ? item.children[0].name : item.name
      this.active = path
      this.subactive = subPath
      // const path = '/' + item.path
      this.$router.push({
        path: path,
        params: {
          active: this.active,
          subactive: subPath
        }
      })
    },
    subClick(item, subitem) {
      this.subactive = subitem.name
      const parentPath = item.redirect ? item.redirect : '/' + item.path
      const path = '/' + item.path + '/' + subitem.path
      this.active = parentPath
      this.$router.push({
        path: path,
        params: {
          active: parentPath,
          subActive: subitem.name
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 398px;
  background: #0096e0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  .nav {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 58px;
    min-height: 160px;
    max-height: 270px;

    .item {
      min-width: 176px;
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        color: #ffffff;
        text-decoration: none;
      }

      .list-top {
        width: 100%;
        height: 26px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        line-height: 23px;
        text-align: left;
        margin-bottom: 8px;
        transition: all 0.5s;
      }
      .list-top:hover {
        cursor: pointer;
        font-size: 24px;
      }
      .followUs:hover {
        cursor: auto;
        font-size: 20px;
      }
      .list-item {
        width: 100%;
        height: 21px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 19px;
        text-align: left;
        margin-bottom: 6px;
        transition: all 0.5s;
      }
      .list-item:hover {
        cursor: pointer;
        font-size: 20px;
      }
      .wechart {
        width: 110px;
        height: 110px;
        background-color: #ffffff;
        margin-top: 8px;
        img {
          width: 110px;
          height: 110px;
        }
      }
    }
    .itemErweima {
      .list-top {
        text-align: center;
      }
      .list-item {
        text-align: center;
      }
    }
  }

  .line {
    width: 1920px;
    height: 0px;
    border: 1px solid rgba(255, 255, 255, 0.35);
  }

  .address {
    // width: 505px;
    // height: 57px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    padding: 18px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      text-decoration: none;
    }
  }
}
</style>
