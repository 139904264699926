// 引入axios文件
import axios from 'axios'
import qs from 'qs'
import { local } from '../utils/local.js'
import context from '../main.js'
// 声明公共的地址
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
// 设置超时
axios.defaults.timeout = 120000
let needLogin = false


axios.defaults.headers["Content-Type"] = "application/json";

axios.interceptors.response.use((res) => {
  // code 错误统一提示
  const { code, message } = res?.data
  if (code || message) {
    if (code == 401) {
      if (!needLogin) {
        needLogin = true
        setTimeout(() => {
          needLogin = false
        }, 1000)
      }
    }
  }
  return res
},
  error => {
    if (error.response?.data?.code == 401) {
      if (!needLogin) {
        needLogin = true
        setTimeout(() => {
          needLogin = false
        }, 1000)
      }
    }
    return
  })

// 对axios进行封装
export default {
  get(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          resolve(res?.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },


  post(url, query) {
    if (!query) {
      query = {}
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, query)
        .then((res) => {
          resolve(res?.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },


  delete(url) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((res) => {
          resolve(res?.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}
